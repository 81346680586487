import { atom, useAtom } from "jotai";
import { UpdateReminderPayload, UserInfo } from "./type";

export const updatePayload: UpdateReminderPayload = {
  details: "",
  id: null,
  assigned_to: null,
  due_date: null,
  due_time: null,
  status_id: null,
};

const tokenAtom = atom<string | undefined>("");

const userDetailsAtom = atom<UserInfo>({
  name: '',
});

const loadingAtom = atom<boolean>(true);

const reminders = atom<any>([]);

const reminderUpdatePayload = atom<UpdateReminderPayload>(updatePayload);

const loading = atom<boolean>(false);

const isOpen = atom<boolean>(false);

const reminderId = atom<number | null>(null);

const singleReminderData = atom<any>([]);

const overdue = atom<any>([]);

const countReminders = atom<any>([]);

function useToken() {
  return useAtom(tokenAtom);
}

function useUserDetails() {
  return useAtom(userDetailsAtom);
}

function useLoading() {
  return useAtom(loadingAtom);
}

function useReminderData() {
  return useAtom(reminders);
}

export function useUpdateReminderData() {
  return useAtom(reminderUpdatePayload);
}

function useReminderLoading() {
  return useAtom(loading);
}

function useIsModalOpen() {
  return useAtom(isOpen);
}

function useReminderId() {
  return useAtom(reminderId);
}

function useSingleReminderData() {
  return useAtom(singleReminderData);
}

function useOverdueReminder() {
  return useAtom(overdue);
}

function useCountReminder() {
  return useAtom(countReminders);
}

export {
  useToken,
  useUserDetails,
  useLoading,
  useReminderData,
  useReminderLoading,
  useIsModalOpen,
  useReminderId,
  useSingleReminderData,
  useOverdueReminder,
  useCountReminder,
};
