import React, { useEffect, useState } from "react";
import {
  getCookie,
  getUrlParams,
  removeCookie,
  setCookie,
} from "./common/utils";
import { useLoading, useToken, useUserDetails } from "./store/store";
import useBeginLogin from "./layout/hooks/useLogin";
import useSsoAuth from "./layout/hooks/useSsoAuth";
import { ID_TOKEN } from "./common/constants";
import { useRef } from "react";
import { useLocalStorage } from "./layout/hooks/useLocalStorage";

const Login = () => {
  const [token, setToken] = useToken();
  const beginLogin = useBeginLogin();
  const ssoAuth = useSsoAuth();
  const [userDetails, setUserDetails] = useUserDetails();
  const [, setLoading] = useLoading();
  const hash = window.location.hash.substring(1);
  const { setItem } = useLocalStorage("userDetails");

  const [latestToken, setlatestToken] = useState<string | null>('')
  // removeItem getItem


  const beginLoginProcess = async () => {
    removeCookie(ID_TOKEN);
    const apiUrl = await beginLogin();
    console.log(apiUrl, 'enter begin login process and give parameter');
    if (apiUrl) {
      window.location.href = apiUrl;
    } else {
      console.error("Error while login");
    }
  };
  const startAuthenticationSsoAuth = async () => {
    if (token) {
      setLoading(true);
      try {
        const userData = await ssoAuth();
        alert(JSON.stringify(userData)+'get data from differnt status sso hook')
        console.log(userData);

        if (userData?.username) {
          setUserDetails({ name: userData.username });
        } else if(userData.detail === 'Token is invalid.'){
          console.log(userData);
          alert(JSON.stringify(userData))
          beginLoginProcess();
        }
        else{
          alert('sso api failed check api ')
        }
      } catch (error) {
        alert('Error during SSO authentication')
        console.error("Error during SSO authentication:", error);
      } finally {
        alert('Error during SSO authentication finally')
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setItem(JSON.stringify(userDetails))


  }, [userDetails]);

  const SignInbeginLogin = async () => {


    let params = getUrlParams(hash);
    let idToken = params.get(ID_TOKEN);
    const existingToken = await getCookie(ID_TOKEN);
    if (idToken) {
      setCookie(ID_TOKEN, idToken);
      setToken(idToken);
      setLoading(false);
      idToken && startAuthenticationSsoAuth()
    }
      
    else if(existingToken){
       startAuthenticationSsoAuth()
    }
    else {
      beginLoginProcess();

    }

  };

  useEffect(() => {
    alert('sign in login ')
    SignInbeginLogin();
  }, [token]);



  return <div></div>;
};

export default Login;
