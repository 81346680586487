import {getBaseEndpoint} from "@bjsdistribution/dms-component-library";
import { useToken } from "../../store/store";

function useSsoAuth() {
  const [token] = useToken();

  const perform = async () => {
    const endpoint = `${getBaseEndpoint()}/sso_auth`;
    try {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(endpoint, requestOptions);
      const json = await response.json();
      if (response.status === 200) {
        return json;
      } else {
        // alert(json.detail);
        console.log(json.detail);
        return [response.status];
      }
    } catch (err) {
      console.log(err, "error");
      return [err];
    }
  };
  return perform;
}

export default useSsoAuth;
